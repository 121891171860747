import { Injectable } from '@angular/core';
import { Observable,of,BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuxiliarService {
 count:number=0;
 state:boolean=true;
 private countB=new BehaviorSubject(0);
 sharedCount=this.countB.asObservable();


 public formState=new BehaviorSubject(true);
 sharedformState=this.formState.asObservable();


  constructor() { }

  add(c){
    this.countB.next(c)
  }

  changeState(){
    this.state=!this.state;
    this.formState.next(this.state);
  }

  getFormState():Observable<number>{
     const state=of(this.count);

     return state
  }

  
}
