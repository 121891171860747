import { FuseNavigation } from '@fuse/types';
/**
 * 
 * cambiar colores estrados homologar
 * cambiar tamaño de e.Justicia
 * 
 * 
 */

const resetFlags = () => {
    sessionStorage.setItem('promotions', 'false');
    sessionStorage.setItem('agreements', 'false');
    sessionStorage.setItem('notifications', 'false');
    sessionStorage.setItem('expedients', 'false');
    sessionStorage.setItem('reports', 'false');
    sessionStorage.setItem('promData', undefined);
}

const dashboardMenu: FuseNavigation = {
    id       : 'sample',
    title    : 'Inicio',
    translate: 'Inicio',
    type     : 'item',
    icon     : 'home',
    url      : '/inicio',
    function : resetFlags
};

const usersPromoventes: FuseNavigation = {
    id      : 'users-promoventes',
    title   : 'Usuarios Promoventes',
    type    : 'item',
    icon    : 'accessibility_new',
    url  : '/users/list/ciudadano',
    children: [
        {
            id   : 'create-promovente',
            title: 'Crear usuario',
            type : 'item',
            url  : '/users/promotors-create'
        },
        {
            id   : 'list-promovente',
            title: 'Listar usuario',
            type : 'item',
            url  : '/users/list/ciudadano'
        }
    ]
};

const userOfficers: FuseNavigation = {
    id      : 'officers',
    title   : 'Gestión de funcionarios',
    type    : 'item',
    icon    : 'supervisor_account',
    url  : '/users/list/empleado',
    children: [
        {
            id   : 'create-promovente',
            title: 'Crear usuario',
            type : 'item',
            url  : '/users/officers-create'
        },
        {
            id   : 'list-promovente',
            title: 'Listar usuario',
            type : 'item',
            url  : '/users/list/empleado'
        }
    ]
};


const usersMenu: FuseNavigation = {
    id      : 'users',
    title   : 'Usuarios',
    type    : 'group',
    icon    : 'emoji_people',
    children: [
        usersPromoventes,
        userOfficers
    ]
}


const amparosMenu: FuseNavigation = {
    id      : 'manager-amparos',
    title   : 'Amparos',
    type    : 'item',
    icon    : 'move_to_inbox',
	url  : '/amparos/all',
	function : resetFlags       
}


const expedientMenu: FuseNavigation = {
    id      : 'manager-expediente',
    title   : 'Servicios',
    type    : 'collapsable',
    icon    : 'important_devices',
    children: [
       /* {
            id   : 'create-expediente',
            title: 'Registro de un Bien Mueble',
            type : 'item',
            icon : 'edit',
            url  : '/management/expedient-create'
        },*/
        {
            id   : 'list-expediente',
            title: 'Estrados Digitales',
            type : 'item',
            icon : 'room_service',
            url  : '/management/expedients'
        },
        {
            id   : 'list-expediente',
            title: 'Listado de acuerdos',
            type : 'item',
            icon : 'list_alt',
            url  : '/management/list-agreements'
        }
    ]
}

const movementsMenu: FuseNavigation = {
        
    id      : 'admin-movimientos',
    title   : 'Nuevo Registro Presencial',
    type    : 'item',
    url     : '/management/expedient-create',
    icon    : 'swap_horizontal_circle',
}

const searchMenu: FuseNavigation = {
    id      : 'search',
    title   : 'Buzón de Oficialía Virtual',
    type    : 'item',
    icon    : 'mail',
    url     : '/promotions/filter/3',
    function : resetFlags,
    children: [
        {
            id   : 'mov-servidores',
            title: 'Pendientes',
            type : 'item',
            icon : 'access_time',
            url  : '/notifications/mail'
        },
        {
            id   : 'mov-unidad',
            title: 'Atendidos',
            type : 'item',
            icon : 'check_circle',
            url  : '/notifications/mail'
        }
    ]
}

const reportsMenu: FuseNavigation = {
    id      : 'search',
    title   : 'Generar Reporte',
    type    : 'item',
    icon    : 'format_list_bulleted',
    url  : '/reports/all',
    function : resetFlags,
    children: [
        {
            id   : 'rep-bienes',
            title: 'Generar Reporte',
            type : 'item',
            icon : 'important_devices',
            url  : '/reports/generate'
        }
    ]
}

export const adminMenu: FuseNavigation[] = [
    dashboardMenu,
   //expedientAdminMenu,
    //notificationMenu,
    usersMenu,
    //authoritiesMenu,
];

export const streamMenu: FuseNavigation[] = [
    dashboardMenu,
    expedientMenu,
    //movementsMenu,
    //searchMenu,
    //reportsMenu
];

export const secretaryMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        movementsMenu,
        searchMenu,
        amparosMenu,
        reportsMenu
    ]
}]
