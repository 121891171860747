import { Injectable } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { adminMenu, secretaryMenu } from "./menu-data";

@Injectable({providedIn: 'root'})
export class MenuService {

    navigation: Array<any>;

    constructor(private _fuseNavigationService: FuseNavigationService,) {
        this.navigation = [];
        
        // Get default navigation
        //this.navigation = navigation;

    }

    addMenu(newNavigation) {
        //set new navigation
        this.navigation = newNavigation;
         // Register the navigation to the service
        this._fuseNavigationService.register('principal', newNavigation);
        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('principal');
    }

    setMenu = (typeUser: String) => {
        //const info = this.auth.getDecodedAccessToken(userToken).data;
        const menu = this._fuseNavigationService.getCurrentNavigation();  
        if( menu == undefined) {
            switch (typeUser) {
                case 'superadmin':
                    this.addMenu(adminMenu);
                    break;
                case 'Oficial de partes':
                    this.addMenu(secretaryMenu);
                    break;
            }    
        }else {
            this.delMenu();
            this.setMenu(typeUser);
        }
    }

    delMenu() {
        //delete current navigation
        this._fuseNavigationService.unregister('principal'); 
    }

}
/* params Menu
id       : 'sample',
title    : 'Dashboard',
translate?: 'NAV.SAMPLE.TITLE',
type     : 'item',
icon     ?: 'email',
url      ?: '/sample',
badge    ?: {
    title    : '25',
    translate?: 'NAV.SAMPLE.BADGE',
    bg       : '#F44336',
    fg       : '#FFFFFF'
}
*/