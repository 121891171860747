export class AppConfig {
    // ######### LOCAL ##########
    //url: string = 'http://apilocal.tjadgo.ml/api/';
    //urlEstrados: string = 'https://boletin.tjadurango.gob.mx/inicio';
    //criptographicEvidenceURL: string = 'http://apilocal.tjadgo.ml/firmarArchivo';
    //CJFCatalogosURL='http://apilocal.tjadgo.ml/cjf';

    // ######### DEPLOYS  ##########
   
    url: string = 'https://api.tjadurango.gob.mx/api/';
    urlEstrados: string = 'https://boletin.tjadurango.gob.mx/inicio';
    criptographicEvidenceURL: string = 'https://api.tjadurango.gob.mx/firmarArchivo';
    CJFCatalogosURL='https://api.tjadurango.gob.mx/cjf';

}                