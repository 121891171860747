import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, Router } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { Error404Module } from 'app/main/pages/errors/404/error-404.module';

import { MenuService } from "./providers/data/menu/menu.service";
import{ MAT_DATE_FORMATS} from '@angular/material/core';

export const MY_DATE_FORMATS = {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    },
};
const appRoutes: Routes = [   

    // Not found
    { path: '**', redirectTo: 'home' },

    {
        path      : 'home',
        redirectTo: 'inicio'
    },
    {
        path        : 'pages',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path        : 'users',
        loadChildren: () => import('./main/users/users.module').then(m => m.UsersModule)
    },
    {
        path        : 'promotions',
        loadChildren: () => import('./main/promotions/promotions.module').then(m => m.PromotionsModule)
    },
    {
        path        : 'management', 
        loadChildren: () => import('./main/expedient/expedient.module').then(m => m.ExpedientModule)
    },
    {
        path        : 'reports',
        loadChildren: () => import('./main/reports/reports.module').then(m => m.ReportsModule)
    },
    {
        path        : 'amparos',
        loadChildren: () => import('./main/amparos/todo.module').then(m => m.AmparosModule)
    },
];

@NgModule({

    providers: [
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
    ],
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,
        Error404Module
    ],
    
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
    constructor(private _router:Router, 
                private _menuService: MenuService) {

        const tokenSession = sessionStorage.getItem('tk');
        if (tokenSession !== null && tokenSession !== undefined) {
            const user = JSON.parse(sessionStorage.getItem('usr'));
            const typeEmployee = sessionStorage.getItem('tyem');
            const typeUser = user.tipo;

            if(typeUser === 'empleado' && typeEmployee === 'Oficial de partes'){
                this._menuService.setMenu(typeEmployee);
            }else {
                this._router.navigate(['/pages/auth/login']);
            }
        }else {
            this._router.navigate(['/pages/auth/login']);
        }

    }   
}
