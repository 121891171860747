export const TypeDoc = [
    {
        id    : 270,
        handle: 'aclaracion',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'ACLARACIÓN DE SENTENCIA',
        title : 'Aclaración de sentencia',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 11,
        handle:'alegato',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'ALEGATOS',
        title : 'Alegatos',
        color : '#EF9800',
        customStyle: ''
    },
    {
        id    : 12,
        handle:'ampliacion',
        registry: 'promocion',
        to_user: 'citizen',
        name: 'AMPLIACIÓN DE DEMANDA',
        title : 'Ampliación de demanda',
        color : '#5E6800',
        customStyle: ''
    },
    /*{
        id    : 14,
        handle:'contestacion',
        registry: 'promocion',
        to_user: 'authority',
        name: 'CONTESTACIÓN DE DEMANDA',
        title : 'Contestación de demanda',
        color : '#FF6800',
        customStyle: ''
    },
    {
        id    : 15,
        handle:'contestampliacion',
        registry: 'promocion',
        to_user: 'authority',
        name: 'CONTESTACIÓN A LA AMPLIACIÓN DE DEMANDA',
        title : 'Contestación a la ampliación de demanda',
        color : '#FF5900',
        customStyle: ''
    },*/
    {
        id    : 22,
        handle: 'comparecencia',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'COMPARECENCIAS',
        title: 'Comparecencias',
        //name: 'COMPARECENCIA ANTE LAS SALAS REGIONALES Y SALA SUPERIOR',
        //title : 'Comprarecencia ante las salas regionales y sala superior',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 23,
        handle: 'cumplimiento',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'CONVENIO DE CUMPLIMIENTO DE SENTENCIA',
        title : 'Convenio de cumplimiento de sentencia',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 230,
        handle: 'cumplimientosustituto',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'CONVENIO DE CUMPLIMIENTO SUSTITUTO DE SENTENCIA',
        title : 'Convenio de cumplimiento sustituto de sentencia',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 8,
        handle: 'correspondencia',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'CORRESPONDENCIA',
        title : 'Correspondencia',
        color : '#dff444',
        customStyle: ''
    },
    {
        id    : 18,
        handle: 'inicialsinsuspension',
        registry: 'inicial',
        to_user: 'citizen',
        name: 'DEMANDA INICIAL CON SOLICITUD DE SUSPENSIÓN',
        title : 'Demanda inicial con solicitud de suspensión',
        color : '#eff444',
        customStyle: ''
    },
    {
        id    : 24,
        handle: 'nuevodomicilio',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'DESIGNACIÓN DE NUEVO DOMICILIO ELECTRÓNICO',
        title : 'Designación de nuevo domicilio electrónico',
        color : '#692414',
        customStyle: 'tag-long'
    },
    {
        id    : 240,
        handle: 'representante',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'DESIGNACIÓN DE REPRESENTANTE COMÚN',
        title : 'Designación de representante común',
        color : '#692414',
        customStyle: 'tag-long'
    },
    {
        id    : 25,
        handle: 'desistimiento',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'DESISTIMIENTO DE LA DEMANDA',
        title : 'Desistimiento de la demanda',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 250,
        handle: 'desistimientorevision',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'DESISTIMIENTO DEL RECURSO DE REVISIÓN',
        title : 'Desistimiento del recurso de revisión',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 260,
        handle: 'ejecucion',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'EJECUCIÓN DE CUMPLIMIENTO DE SENTENCIA',
        title : 'Ejecución de cumplimiento de sentencia',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 26,
        handle: 'excitativa',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'EXCITATIVA DE JUSTICIA',
        title : 'Excitativa de justicia',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 280,
        handle: 'excusas',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'EXCUSAS',
        title : 'Excusas',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 280,
        handle: 'impedimentos',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'IMPEDIMENTOS',
        title : 'impedimentos',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 290,
        handle: 'inactividad',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'INACTIVIDAD PROCESAL',
        title : 'Inactividad procesal',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 27,
        handle: 'aclaracion',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'INCIDENTE DE ACLARACIÓN DE SENTENCIA',
        title : 'incidente de aclaración de sentencia',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 28,
        handle: 'acumulacion',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'INCIDENTE DE ACUMULACIÓN DE AUTOS',
        title : 'Incidente de acumulación de autos',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 29,
        handle: 'sustituto',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'INCIDENTE DE CUMPLIMIENTO SUSTITUTO',
        title : 'Incidente de cumplimiento sustituto',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 31,
        handle: 'incompetencia',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'INCIDENTE DE INCOMPETENCIA POR RAZÓN DE TERRITORIO',
        title : 'incidente de incompetencia por razón de territorio',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 32,
        handle: 'disolucion',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'INCIDENTE DE INTERRUPCIÓN DEL PROCEDIMIENTO POR MUERTE O DISOLUCIÓN DE LAS PERSONAS MORALES',
        title : 'Incidente de interrupción del procedimiento por muerte o disolución de las personas morales',
        color : '#692414',
        customStyle: 'tag-long'
    },
    {
        id    : 33,
        handle: 'nulidad',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'INCIDENTE DE NULIDAD DE NOTIFICACIONES',
        title : 'Incidente de nulidad de notifaciones',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 13,
        handle:'directo',
        registry: 'promocion',
        to_user: 'citizen',
        name: 'JUICIO DE AMPARO DIRECTO',
        title : 'Juicio de amparo directo',
        color : '#DF6800',
        customStyle: ''
    },
    {
        id    : 133,
        handle:'indirecto',
        registry: 'promocion',
        to_user: 'citizen',
        name: 'JUICIO DE AMPARO INDIRECTO',
        title : 'Juicio de amparo indirecto',
        color : '#313800',
        customStyle: ''
    },
    {
        id    : 16,
        handle: 'suspensioninicial',
        registry: 'inicial',
        to_user: 'authority',
        name: 'JUICIO DE LESIVIDAD',
        title : 'Juicio de lesividad',
        color : '#9ee824',
        customStyle: ''
    },
    {
        id    : 160,
        handle: 'inicial',
        registry: 'inicial',
        to_user: 'citizen',
        name: 'JUICIO DE NULIDAD',
        title : 'Juicio de nulidad',
        color : '#9ee824',
        customStyle: ''
    },
    {
        id    : 1,
        handle: 'inicial',
        registry: 'inicial',
        to_user: 'authortyes',
        name: 'JUICIO DE NULIDAD',
        title : 'Juicio de nulidad',
        color : '#388E3C',
        customStyle: ''
    },
    {
        id    : 19,
        handle: 'responsabilidad',
        registry: 'inicial',
        to_user: 'citizen',
        name: 'JUICIO POR RESPONSABILIDAD ADMINISTRATIVA GRAVE',
        title : 'Juicio por responsabilidad administrativa grave',
        color : '#692444',
        customStyle: ''
    },
    {
        id    : 9,
        handle: 'lesividad',
        registry: 'inicial',
        to_user: 'authortyes',
        name: 'LESIVIDAD',
        title : 'Lesividad',
        color : '#ef3994',
        customStyle: ''
    },
    {
        id    : 4,
        handle: 'promocion',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'PROMOCIÓN',
        title : 'Promoción',
        color : '#FF9800',
        customStyle: ''
    },
    {
        id    : 17,
        handle:'suspensionpromocion',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'PROMOCIÓN RELACIONADA CON SUSPENSIÓN',
        title : 'Promoción relacionada con suspensión',
        color : '#AF6800',
        customStyle: ''
    },
    {
        id    : 20,
        handle: 'apelacion',
        registry: 'inicial',
        to_user: 'citizen',
        name: 'RECURSO DE APELACIÓN',
        title : 'Recurso de apelación',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 21,
        handle: 'inconformidad',
        registry: 'inicial',
        to_user: 'citizen',
        name: 'RECURSO DE INCONFORMIDAD',
        title : 'Recurso de inconformidad',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 21,
        handle: 'queja',
        registry: 'inicial',
        to_user: 'citizen',
        name: 'RECURSO DE QUEJA',
        title : 'Recurso de queja',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 21,
        handle: 'reclamacion',
        registry: 'inicial',
        to_user: 'citizen',
        name: 'RECURSO DE RECLAMACIÓN',
        title : 'Recurso de reclamación',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 5,
        handle: 'recurso',
        registry: 'inicial',
        to_user: 'citizen',
        name: 'RECURSO DE REVISIÓN',
        title : 'Recurso de revisión inicial',
        color : '#0091EA',
        customStyle: ''
    },
    {
        id    : 34,
        handle: 'autorizados',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'REVOCACIÓN DE AUTORIZADOS Y DESIGNACIÓN DE NUEVOS AUTORIZADOS',
        title : 'Revocación de autorizados y designación de nuevos autorizados',
        color : '#692414',
        customStyle: 'tag-long'
    },
    {
        id    : 35,
        handle: 'copias',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'SOLICITUD DE COPIAS',
        title : 'solicitud de copias',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 200,
        handle: 'solicitudcumplimiento',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'SOLICITUD DE CUMPLIMIENTO Y EJECUCIÓN DE SANCIONES POR FALTAS ADMINISTRATIVAS GRAVES',
        title : 'Solicitud de cumplimiento y ejecución de sanciones por faltas administrativas graves',
        color : '#395E3C',
        customStyle: ''
    },
    {
        id    : 201,
        handle: 'solicitudfianzas',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'SOLICITUD DE DEVOLUCIÓN DE FIANZAS',
        title : 'Solicitud de devolución de fianzas',
        color : '#9C27B0',
        customStyle: 'tag-long'
    },
    {
        id    : 350,
        handle: 'solicitudcautelares',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'SOLICITUD DE MEDIDAS CAUTELARES',
        title : 'Solicitud de medidas cautelares',
        color : '#692414',
        customStyle: ''
    },
    {
        id    : 360,
        handle: 'sustituciontestigos',
        registry: 'promocion',
        to_user: 'citizen authority',
        name: 'SUSTITUCÓN DE TESTIGOS',
        title : 'Sustitución de testigos',
        color : '#692414',
        customStyle: ''
    },
]

export const TypeFile = [
    { name: 'copia simple', uid:'simple'},
    { name: 'copia certificada', uid:'certificada'},
    { name: 'original con firma', uid:'firmada'},
    { name: 'original sin firma', uid:'original'},
]

export const TipoPromovente = [
    {  nume:'1', type:'promocion revision', name:'Actor'},
    {  nume:'2', type:'promocion revision', name:'Autoridad Demandada'},
    {  nume:'3', type:'promocion revision', name:'Tercero Perjudicado'},
    {  nume:'4', type:'promocion', name:'Recurrente'},
    {  nume:'5', type:'promocion revision', name:'Otro'},
    {  nume:'6', type:'especializada', name:'Presunto responsable'},
    {  nume:'7', type:'especializada', name:'Investigadora'},
    {  nume:'8', type:'especializada', name:'Substanciadora'},
    {  nume:'9', type:'especializada', name:'Resolutora'},
    {  nume:'10', type:'especializada', name:'Terceros'},
    {  nume:'11', type:'especializada', name:'Otra'},
]

export const TipoProcedimiento = [
    { nume: 1, name:'Juicio ordinario'},
    { nume: 2, name:'Juicio sumario'},
    { nume: 8, name:'Procedimiento de responsabilidades por falta administrativa grave'},
    { nume: 9, name:'Procedimiento de cumplimiento de sentencia'},
    { nume: 10, name:'Conflicto de competencia'},
    { nume: 11, name:'Excitativa de justicia'},
    { nume: 12, name:'Excusa'},
];

export const Responsabilidad = [
    {  nume:'0', name:'Responsabilidad Administrativa'},
    {  nume:'0', name:'Recurso de Inconformidad'},
    {  nume:'0', name:'Recurso de Reclamación'},
    {  nume:'0', name:'Recurso de Apelación'},
]

export const OrganoJurisdiccional = [
    { 
        nume:1, 
        depa:'Sala Superior',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento cumplimientosustituto correspondencia nuevodomicilio representante desistimiento desistimientorevision ejecucion excitativa excusas impedimentos inactividad aclaracion acumulacion sustituto incompetencia disolucion directo indirecto responsabilidad promocion suspensionpromocion apelacion inconformidad recurso autorizados copias solicitudcumplimiento solicitudfianzas solicitudcautelares sustituciontestigos', 
    },
    {
        nume:2,
        depa: 'Primera Sala'
    },
    {   nume:3, 
        depa:'Segunda Sala',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento cumplimientosustituto correspondencia inicialsinsuspension nuevodomicilio representante desistimiento desistimientorevision ejecucion excitativa inactividad aclaracion acumulacion sustituto disolucion nulidad directo suspensioninicial inicial responsabilidad lesividad promocion suspensionpromocion queja reclamacion autorizados copias solicitudcumplimiento solicitudfianzas solicitudcautelares sustituciontestigos',
    }
]

export const OrganoJurisdiccionalCorrespondencia = [
    { 
        nume:1, 
        depa:'Sala Superior',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento cumplimientosustituto correspondencia nuevodomicilio representante desistimiento desistimientorevision ejecucion excitativa excusas impedimentos inactividad aclaracion acumulacion sustituto incompetencia disolucion directo indirecto responsabilidad promocion suspensionpromocion apelacion inconformidad recurso autorizados copias solicitudcumplimiento solicitudfianzas solicitudcautelares sustituciontestigos', 
    },
    {   nume:2, 
        depa:'Sala Regional Acapulco I',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento cumplimientosustituto correspondencia inicialsinsuspension nuevodomicilio representante desistimiento desistimientorevision ejecucion excitativa inactividad aclaracion acumulacion sustituto disolucion nulidad directo suspensioninicial inicial responsabilidad lesividad promocion suspensionpromocion queja reclamacion autorizados copias solicitudcumplimiento solicitudfianzas solicitudcautelares sustituciontestigos',
    },
    {   nume:3, 
        depa:'Sala Regional Acapulco II',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento cumplimientosustituto correspondencia inicialsinsuspension nuevodomicilio representante desistimiento desistimientorevision ejecucion excitativa inactividad aclaracion acumulacion sustituto disolucion nulidad directo suspensioninicial inicial responsabilidad lesividad promocion suspensionpromocion queja reclamacion autorizados copias solicitudcumplimiento solicitudfianzas solicitudcautelares sustituciontestigos',
    },
    {   nume:4, 
        depa:'Sala Regional Altamirano',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento cumplimientosustituto correspondencia inicialsinsuspension nuevodomicilio representante desistimiento desistimientorevision ejecucion excitativa inactividad aclaracion acumulacion sustituto disolucion nulidad directo suspensioninicial inicial responsabilidad lesividad promocion suspensionpromocion queja reclamacion autorizados copias solicitudcumplimiento solicitudfianzas solicitudcautelares sustituciontestigos',
    },
    {   nume:5, 
        depa:'Sala Regional Chilpancingo',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento cumplimientosustituto correspondencia inicialsinsuspension nuevodomicilio representante desistimiento desistimientorevision ejecucion excitativa inactividad aclaracion acumulacion sustituto disolucion nulidad directo suspensioninicial inicial responsabilidad lesividad promocion suspensionpromocion queja reclamacion autorizados copias solicitudcumplimiento solicitudfianzas solicitudcautelares sustituciontestigos',
    },
    {   nume:6, 
        depa:'Sala Regional Iguala',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento cumplimientosustituto correspondencia inicialsinsuspension nuevodomicilio representante desistimiento desistimientorevision ejecucion excitativa inactividad aclaracion acumulacion sustituto disolucion nulidad directo suspensioninicial inicial responsabilidad lesividad promocion suspensionpromocion queja reclamacion autorizados copias solicitudcumplimiento solicitudfianzas solicitudcautelares sustituciontestigos',
    },
    {   nume:7, 
        depa:'Sala Regional Ometepec',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento cumplimientosustituto correspondencia inicialsinsuspension nuevodomicilio representante desistimiento desistimientorevision ejecucion excitativa inactividad aclaracion acumulacion sustituto disolucion nulidad directo suspensioninicial inicial responsabilidad lesividad promocion suspensionpromocion queja reclamacion autorizados copias solicitudcumplimiento solicitudfianzas solicitudcautelares sustituciontestigos',
    },
    {   nume:8, 
        depa:'Sala Regional Tlapa',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento cumplimientosustituto correspondencia inicialsinsuspension nuevodomicilio representante desistimiento desistimientorevision ejecucion excitativa inactividad aclaracion acumulacion sustituto disolucion nulidad directo suspensioninicial inicial responsabilidad lesividad promocion suspensionpromocion queja reclamacion autorizados copias solicitudcumplimiento solicitudfianzas solicitudcautelares sustituciontestigos',
    },
    {   nume:9, 
        depa:'Sala Regional Zihuatanejo',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento cumplimientosustituto correspondencia inicialsinsuspension nuevodomicilio representante desistimiento desistimientorevision ejecucion excitativa inactividad aclaracion acumulacion sustituto disolucion nulidad directo suspensioninicial inicial responsabilidad lesividad promocion suspensionpromocion queja reclamacion autorizados copias solicitudcumplimiento solicitudfianzas solicitudcautelares sustituciontestigos',
    }
]


export const TipoRecurso = [
    { nume: 1, name:'Juicio de nulidad'},
    /*{ nume: 2, name:'Juicio sumario'},*/
    { nume: 3, name:'Recurso de revisión'},
    { nume: 4, name:'Recurso de revocación'},
    { nume: 5, name:'Recurso de reclamación'},
    { nume: 6, name:'Recurso de inconformidad'},
    { nume: 7, name:'Recurso de apelación'},
    { nume: 8, name:'Procedimiento de responsabilidades administrativas'},
    { nume: 9, name:'Procedimiento de cumplimiento de sentencia'},
    { nume: 10, name:'Conflicto de competencia'},
    { nume: 11, name:'Excitativa de justicia'},
   // { nume: 12, name:'Excusa'},
];

export class FileAnexo {
    name:string;
    file: any;
    error: boolean;
    upload: boolean;
    size: string;
    type: string;
    desc: string;
    txtBtnUp: string;
    numPages: string;
    txtError: string;
    constructor(name: string){
        { 
            this.name = name; 
            this.file = undefined;
            this.error = false; 
            this.txtError = '';
            this.upload = false;
            this.size = '';
            this.type = '';
            this.desc = ''; 
            this.txtBtnUp = 'Agregar archivo';
            this.numPages = '0';
        }
    }
} 

export const TipoJuicios = [
    { nume: 1,  name: 'Juicio Administrativo'},
    { nume: 2,  name: 'Juicio Fiscal'},
    { nume: 15, name:  'Juicio de Lesividad'},
    { nume: 3,  name: 'Recurso de Queja'},
    { nume: 4,  name: 'Recurso de Revisión'},
    { nume: 5,  name: 'Cumplimiento de Sentencia en Sala Superior'},
    { nume: 6,  name: 'Cuadernillo de Amparo en Sala Superior'},
    { nume: 7,  name: 'Excitativas de Justicia'},
    { nume: 8,  name: 'Conflicto de Competencia entre Salas Regionales'},
    { nume: 9,  name: 'Procedimiento de Responsabilidad Administrativa'},
    { nume: 10, name:  'Recurso de Reclamación'},
    { nume: 11, name:  'Recurso de Apelación'},
    { nume: 12, name:  'Procedimiento de Responsabilidades Administrativas'},
    { nume: 16, name:  'Procedimiento de Responsabilidades Administrativas por Faltas Graves'},
    { nume: 13, name:  'Recurso de Inconformidad'},
    { nume: 14, name:  'Incidentes'},

   /* { nume: 15, name:  'Investigación'},*/
];

export const ListadoAcuerdos = [
    {nume: 0,  name:'Otro'},
    {nume: 1,  name:'Admisión'},
    {nume: 2,  name:'Desechamiento'},
    {nume: 3,  name:'Ampliación'},
    {nume: 4,  name:'Sentencia'},
    {nume: 5,  name:'Contestación de Demanda'},
    {nume: 6,  name:'Audiencia'},
    {nume: 7,  name:'Nulidad de Notificación'},
    {nume: 8,  name:'Acuerdo de recepción de expediente'},
    {nume: 9,  name:'Acuerdo de reclasificación'},
    {nume: 10, name: 'Acuerdo de devolución'},
    {nume: 11, name: 'Acuerdo de envío'},
    {nume: 12, name: 'Cierre de instrucción'},
    {nume: 13, name: 'Audiencia diferida'},
    {nume: 14, name: 'Contestación a la ampliación'},
    {nume: 15, name: 'Ampliación de demanda'},
    {nume: 16, name: 'Manifestaciones'},
    {nume: 17, name: 'Exhibe expediente'},
    {nume: 18, name: 'Ofrecimiento de pruebas'},
    {nume: 19, name: 'Aclaración sentencia'},
    {nume: 20, name: 'Desahogo'},
    {nume: 21, name: 'Reposición '},
    {nume: 22, name: 'Copias'},
    {nume: 23, name: 'Alegatos'},
    {nume: 24, name: 'Cumplimiento'},
    {nume: 25, name: 'Conciliación'},
    {nume: 26, name: 'Ejecutoria'},
    {nume: 27, name: 'Archivo'},
    {nume: 28, name: 'Incompetencia'},
    /*{nume: 29, name: 'Túrnese a proyectos'},*/
    {nume: 30, name: 'Acuse de recibo'},
    {nume: 31, name: 'Admisión de pruebas'},
    {nume: 32, name: 'Requerimiento'},
    {nume: 33, name: 'Desahogo de requerimiento'},
    {nume: 34, name: 'Presentación de alegatos'},
    {nume: 35, name: 'Remisión a sala superior'},
    {nume: 36, name: 'Acuerdo donde señala domicilio el presunto, la investigadora o substanciadora'},
    {nume: 37, name: 'Regularización'},
    {nume: 38, name: 'Reserva'},
    {nume: 39, name: 'Admisión de queja'},
    {nume: 40, name: 'Recepción de documentos'},
    {nume: 41, name: 'Acumulación'},
    {nume: 42, name: 'Conclusión'},
    {nume: 43, name: 'Remisión'},
    {nume: 44, name: 'Sala a la que se remite'},
];

export const TipoParte = [
    { nume: 1, name: 'Actor'},
    { nume: 2, name: 'Autoridad demandada'},
    { nume: 3, name: 'Tercer perjudicado'},
    { nume: 4, name: 'Recurrente'},
    { nume: 5, name: 'Autoridad vinculada'},
    /*{ nume: 6, name: 'Delegado fiscal'},*/
    { nume: 7, name: 'Juzgado de distrito'},
    { nume: 8, name: 'Órgano jurisdiccional exhortante'},
    { nume: 45, name: 'Autoridad acusadora'},
    { nume: 9, name: 'Autoridad investigadora'},
    { nume: 10, name: 'Autoridad substanciadora'},
    { nume: 11, name: 'Presunto responsable'},
    { nume: 47, name: 'Presunto infractor'},
    { nume: 12, name: 'Denunciante'},
    { nume: 13, name: 'Notifíquese'},
    /*
    Recurso de apelación
    Recurso de inconformidad

    Alegatos
    Ampliacion
    Correspondencia
    Excitativa de justicia
    Incidente de nulidad de notificaciones
    Juicio de amparo (solicita informes previos)
    Promocion relacionada con suspension
    Promocion
    

    { nume: 14, name: 'Actor - Recurrente'},
    { nume: 15, name: 'Actor - Tercer perjudicado'},
    { nume: 16, name: 'Actor - Autoridad vinculada'},
    { nume: 17, name: 'Actor - Delegado fiscal'},
    { nume: 18, name: 'Actor - Juzgado de distrito'},
    { nume: 19, name: 'Actor - Órgano jurisdiccional exhortante'},
    { nume: 20, name: 'Actor - Autoridad investigadora'},
    { nume: 21, name: 'Actor - Autoridad substanciadora'},
    { nume: 22, name: 'Actor - Presunto responsable'},
    { nume: 23, name: 'Actor - Denunciante'},
    { nume: 24, name: 'Actor - Notifíquese'},
    { nume: 25, name: 'Actor - Autoridad Demandada'},
    { nume: 26, name: 'Actor - Autoridad Demandada - Recurrente'},
    { nume: 27, name: 'Actor - Autoridad Demandada - Tercer perjudicado'},
    { nume: 28, name: 'Actor - Autoridad Demandada - Autoridad vinculada'},
    { nume: 29, name: 'Actor - Autoridad Demandada - Delegado fiscal'},
    { nume: 30, name: 'Actor - Autoridad Demandada - Juzgado de distrito'},
    { nume: 31, name: 'Actor - Autoridad Demandada - Entidad exhortante'},
    { nume: 32, name: 'Actor - Autoridad Demandada - Autoridad investigadora'},
    { nume: 33, name: 'Actor - Autoridad Demandada - Autoridad substanciadora'},
    { nume: 34, name: 'Actor - Autoridad Demandada - Presunto responsable'},
    { nume: 35, name: 'Actor - Autoridad Demandada - Denunciante'},
    { nume: 36, name: 'Actor - Autoridad Demandada - Notifíquese'},
    { nume: 37, name: 'Autoridad investigadora - Autoridad substanciadora - Presunto responsable - Denunciante'},
    { nume: 38, name: 'Autoridad investigadora - Autoridad substanciadora - Presunto responsable'},
    { nume: 39, name: 'Autoridad investigadora - Autoridad substanciadora'},
    /*{ nume: 40, name: 'Cúmplase'},*/
    { nume: 41, name: 'Tribunal Colegiado de Circuito'},
    { nume: 42, name: 'Auditoria Superior del Estado de Guerrero'},
    /*{ nume: 43, name: 'Interesados'},*/
    { nume: 44, name: 'Tribunal de Conciliación y Arbitraje del Estado de Guerrero'},
    { nume: 46, name: 'Tribunal Electoral del Estado de Guerrero'},
];

export const ListadoAcuerdosSalas = [
    { 
        nume:1, 
        name:'Sala Superior',
        conf:'copias autorizados nuevodomicilio comparecencia cumplimiento sutituto directo indirecto reclamacion aclaracion', 
    },
    {   nume:2, 
        name:'Sala Regional Acapulco I',
        conf:'inicial inicialsinsuspension suspensioninicial responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo indirecto aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:3, 
        name:'Sala Regional Acapulco II',
        conf:'inicial inicialsinsuspension suspensioninicial responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo indirecto aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:4, 
        name:'Sala Regional Ciudad Altamirano',
        conf:'inicial inicialsinsuspension suspensioninicial responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo indirecto aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:5, 
        name:'Sala Regional Chilpancingo',
        conf:'inicial inicialsinsuspension suspensioninicial responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo indirecto aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:6, 
        name:'Sala Regional Iguala',
        conf:'inicial inicialsinsuspension suspensioninicial responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo indirecto aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:7, 
        name:'Sala Regional Ometepec',
        conf:'inicial inicialsinsuspension suspensioninicial responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo indirecto aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:8, 
        name:'Sala Regional Tlapa',
        conf:'inicial inicialsinsuspension suspensioninicial responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo indirecto aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:9, 
        name:'Sala Regional Zihuatanejo',
        conf:'inicial inicialsinsuspension suspensioninicial responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo indirecto aclaracion queja reclamacion acumulacion disolucion incompetencia',
    }
];

export const TipoSentido = [
    {nume: 1,  name:'Incidente de tacha de testigos'},
    {nume: 2,  name:'Resolución de tacha de testigos'},
    {nume: 3,  name:'Apertura de periodos de alegatos '},
    /*{nume: 4,  name:'Turno de sentencia'},*/
    {nume: 5,  name:'Admisión y/o presentación de escrito inicial'},
    {nume: 6,  name:'Desahogo de vista'},
    {nume: 7,  name:'Certificación secretarial'},
    {nume: 10,  name:'Se recibe expediente'},
    {nume: 11,  name:'Acuerdo de regularización'},
    {nume: 12,  name:'Autorización de copias'},
    /*{nume: 13,  name:'Túrnese a proyecto'},*/
    {nume: 14,  name:'Acuerdo de arreglos conciliatorios'},
    {nume: 15,  name:'No se tiene por cumplida la sentencia y se requiere'},
    {nume: 16,  name:'Admisión de prueba superveniente'},
    {nume: 17,  name:'Sentencia'},
    {nume: 18,  name:'Cause ejecutoria'},
    {nume: 19,  name:'Archivo'},
    {nume: 20,  name:'Vistas actor'},
    {nume: 21,  name:'Vistas particular'},
    {nume: 22,  name:'Desahogo requerimiento autoridad'},
    {nume: 23,  name:'Desahogo requerimiento actor'},
    {nume: 24,  name:'Desahogo requerimiento tercero'},
    {nume: 25,  name:'Desahogo de expediente formado'},
    {nume: 27,  name:'Difiere audiencia'},
    {nume: 28,  name:'Se nombra autorizados'},
    {nume: 29,  name:'Se autoriza la expedición de copias simples'},
    {nume: 30,  name:'Se autoriza la expedición de copias certificadas'},
    {nume: 31,  name:'Vincula autoridad cumplimiento'},
    {nume: 32,  name:'Llamar nueva autoridad demandada'},
    {nume: 34,  name:'Cuantificación liquidación'},
    {nume: 35,  name:'Sentencia no cumplida'},
    {nume: 36,  name:'No ha lugar a acordar de conformidad por no ser parte'},
    {nume: 38,  name:'Requerimiento al actor'},
    {nume: 39,  name:'Requerimiento a la autoridad '},
    {nume: 40,  name:'Agréguese a los autos para los efectos legales a que haya lugar'},
    {nume: 41,  name:'Vías de cumplimiento'},
    {nume: 42,  name:'Se concede prórroga cumplimiento'},
    {nume: 43,  name:'Se concede prórroga  para desahogo requerimiento'},
    {nume: 44,  name:'Requerimiento no cumplido'},
    {nume: 45,  name:'Búsqueda exhaustiva'},
    {nume: 46,  name:'Estése a lo acordado '},
    {nume: 48,  name:'Autoridades confesas'},
    {nume: 49,  name:'Autoridad se allana'},
    {nume: 50,  name:'Requerimiento de Sala Superior'},
    {nume: 51,  name:'Tercero perjudicado se apersona'},
    {nume: 52,  name:'Juzgado de distrito solicita informe previo'},
    {nume: 53,  name:'Juzgado de distrito solicita informe justificado'},
    {nume: 54,  name:'Juzgado de distrito concede suspensión'},
    {nume: 55,  name:'Juzgado de distrito sin efectos suspensión'},
    {nume: 56,  name:'Juzgado de distrito notifica sentencia'},
    {nume: 57,  name:'Juzgado de distrito tiene por cumplida sentencia'},
    {nume: 58,  name:'Juzgado de distrito tiene por no cumplida sentencia'},
    {nume: 59,  name:'Requiere y sanciona con multa autoridad'},
    {nume: 60,  name:'Solicitud de datos personales para imposición de sanción autoridad'},
    {nume: 61,  name:'Remisión de datos para ejecutar sanción a autoridad'},
    {nume: 62,  name:'Cumplimiento parcial de sentencia y requiere cumplimiento'},
    {nume: 63,  name:'Remite juicio de diversa autoridad'},
    {nume: 64,  name:'Se admite el recurso interpuesto'},
    {nume: 65,  name:'Se desecha el recurso interpuesto'},
    {nume: 66,  name:'Se apercibe para señalar domicilio dentro de la sede de la sala regional o sala superior'},
    {nume: 67,  name:'Se hace efectivo el apercibimiento decretado de autos'},
    {nume: 68,  name:'Se tiene por señalado el domicilio para oír y recibir notificaciones y por autorizados a los profesionistas'},
    {nume: 69,  name:'Se ordena dar vista y se señala plazo para desahogo'},
    {nume: 70,  name:'Se tiene por desahogada la vista y por hechas las manifestaciones'},
    {nume: 71,  name:'Se tiene por autorizados a los profesionistas'},
    {nume: 72,  name:'No ha lugar a acordar de conformidad lo solicitado'},
    {nume: 74,  name:'Se turna a ponencia y se designa magistrado ponente para la formulación del proyecto de resolución'},
    {nume: 75,  name:'Se ordena devolver los autos a la Sala Regional de origen'},
    {nume: 76,  name:'Se previene'},
    {nume: 78,  name:'Se apercibe'},
    {nume: 79,  name:'Se declara preclusión'},
    {nume: 80,  name:'Se tiene por presentado y se ordena glosar a los autos'},
    {nume: 81,  name:'Se remite acuse de recibo correspondiente'},
    {nume: 83,  name:'Se ordena girar oficio'},
    {nume: 84,  name:'Se recibe expedientes de Sala Superior'},
    {nume: 85,  name:'Se presenta demanda de Amparo Directo'},
    {nume: 86,  name:'Se remite expediente al Tribunal Colegiado'},
    {nume: 87,  name:'No ha lugar de admitir prueba superveniente'},
    {nume: 88,  name:'Se recibe expediente de Tribunal Colegiado'},
    {nume: 89,  name:'Validez'},
    {nume: 90,  name:'Invalidez'},
    {nume: 91,  name:'Sobresee'},
    {nume: 92,  name:'Confirma sentencia'},
    {nume: 93,  name:'Modifica sentencia'},
    {nume: 94,  name:'Revoca sentencia'},
    {nume: 95,  name:'Se concede suspensión en auxilio de autoridad de amparo'},
    {nume: 96,  name:'Otro'},
    {nume: 97,  name:'Desechamiento'},
    {nume: 98,  name:'Audiencia'},
    {nume: 99,  name:'Reclasificación'},
    {nume: 100, name: 'Devolución'},
    {nume: 101, name: 'Envío'},
    {nume: 102, name: 'Cierre de instrucción'},
    {nume: 103, name: 'Incompetencia'},
    {nume: 104, name: 'Admisión de pruebas'},
    {nume: 105, name: 'Presentación de alegatos'},
    {nume: 106, name: 'Señala domicilio el presunto'},
    {nume: 107, name: 'Reserva'},
    {nume: 108, name: 'Acumulación'},
    {nume: 109, name: 'Remisión'},
];