<!-- BOARDS -->
<div id="boards" class="fuse-white texture-background-employee" fxLayout="column" fxLayoutAlign="start center" fxFlex fusePerfectScrollbar>
  <div class="back-1"></div>
  <div class="header" fxLayout="row" fxLayoutAlign="center center" fxFlex="0 0 auto"
       [@animate]="{value:'*',params:{delay:'100ms'}}">
      <div class="logo-tri" [@animate]="{value:'*',params:{scale:'0.5'}}">
          <img width="130" src="assets/images/logos/logogro.png">
      </div>

      <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
        <h1><span class="name_title">e</span>.Justicia Administrativa Durango</h1>
      </div>
  </div>

  <!-- BOARD LIST -->
  <section   fxFlex="0 0 auto" fxLayout="column wrap" fxLayoutAlign="center center">
    <!--div class="logo"  fxLayout="row wrap"[@animate]="{value:'*',params:{scale:'0.2'}}">
      <mat-icon class="s-24">{{option.icon}}</mat-icon>
      <h2>{{option.name}}</h2>
    </div-->
  <div class="board-list" fxFlex="0 0 auto" fxLayout="row wrap" fxLayoutAlign="center center"
       [@animateStagger]="{value:'50'}" >
      <!-- BOARD -->
      <div class="board-list-item {{board.id}}" *ngFor="let board of boards"
           [routerLink]="[board.path]" routerLinkActive="router-link-active" 
           fxLayout="column" fxLayoutAlign="center center"
           [@animate]="{value:'*',params:{y:'100%'}}">
          <mat-icon class="s-56">{{board.icon}}</mat-icon>
          <div class="board-name">{{board.name}}</div>
      </div>
      <!-- / BOARD -->

      <!-- NEW BOARD BUTTON >
      <div class="board-list-item add-new-board" fxLayout="column" fxLayoutAlign="center center"
           (click)="newBoard()"
           [@animate]="{value:'*',params:{y:'100%'}}">
          <mat-icon class="s-56">add_circle</mat-icon>
          <div class="board-name">Add new board</div>
      </div>
      <!-- / NEW BOARD BUTTON -->

  </div>
  <!-- / BOARD LIST -->
  <div class="back-2"></div>

  </section>
</div>
<!-- / BOARDS -->
